import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
               
  URI:string = 'https://avaluos.itelisoft.click/api-autorama/public/api/vehicle/';

  constructor(private http:HttpClient) { }

  getVehicles():Observable<any>{

    return this.http.get(this.URI+'consult/vehicles/portal');
  }
  getOfferVehicles():Observable<any>{
    return this.http.get(this.URI+'consult/vehicles/offer/portal');
  }
  getVehicle(id:string):Observable<any>{
    return this.http.get(this.URI+'consult/vehicle/'+id);
  }
}
