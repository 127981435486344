import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../common/models/vehicle.model';
import { VehiclesService } from '../common/services/vehicles.service';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

  vehicles: Array<Vehicle> = [];
  load = true;

  constructor(private service: VehiclesService) { }


  ngOnInit() {
    this.getVehicles().then(() => {
    });
  }

  getVehicles = () => {
    return new Promise((resolve) => {
      this.service.getVehicles().subscribe(res => {
        this.vehicles = res;
        this.load = false;
        resolve();
      });
    });
  }

  getTheFirstImage(_images: string): string {
    const images: string[] = JSON.parse(_images);
    const img: string =  'https://avaluos.itelisoft.click/resource/images/' + images[0];
    // console.log(img);
    return img;
  }

  stylingText(str: string): string {
    let _str = str.toLowerCase();
    return _str;
  }

  getKilometers(str: string): string {
    // console.log('Kilometraje: ', str);
    let newString = str;
    newString = newString.replace(/[^\d]/g, '');
    // console.log('newString: ', newString);


    const mileage_array = newString.split('');
    if (mileage_array.length <= 3) {
      return newString;
    }
    if (mileage_array.length > 6) {
      mileage_array.splice(mileage_array.length - 6, 0, ',');
    }
    mileage_array.splice(mileage_array.length - 3, 0, ',');
    newString = mileage_array.join('');
    return newString;
  }

  getPriceFormat(str: string): string {
    let price: string = str;
    let price_array = price.split('');
    price_array.splice(price_array.length - 3, 0, ',');
    price = price_array.join('');
    return price;
  }

}
