import { Component, OnInit, HostListener } from '@angular/core';
import { Vehicle } from '../common/models/vehicle.model';
import { VehiclesService } from '../common/services/vehicles.service';
import { Router } from '@angular/router';

interface Filter {
  topic: string,
  type: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  width:number;

  // @HostListener("window:scroll", ['$event'])
  // doSomethingOnWindowsScroll($event: any) {
  //   this.width = window.innerWidth;
  //   console.log( window.innerWidth);
  //   console.log($event.srcElement.children[0].scrollTop);

  // }

  load: boolean;

  vehicles: Vehicle[] = [];
  vehiclesOriginal: Vehicle[] = [];

  //-------TOPICS-------
  makers: string[] = [];
  models: string[] = [];
  years: string[] = [];
  colors: string[] = [];
  types: string[] = [];
  transmissions: string[] = [];
  currentMaker: string = 'Marca';
  currentModel: string = 'Modelo';
  currentYear: string = 'Año';
  currentColor: string = 'Color';
  currentTransmission: string = 'Transmisión';
  currentTypes: string = 'Tipo';

  //------RANGE-STUFF----------
  minPrice: number = 9999999;
  maxPrice: number = 99999;
  // minPrice: number = 50000;
  // maxPrice: number = 800000;
  realRange: number;
  convertIndex: number;
  val1: number = 0;
  val2: number = 200;
  currentMinVal: number = 100000;
  currentMaxVal: number = 100000;
  line;

  //-------FILTERS-----
  filters: Filter[] = [];

  constructor(
    private service: VehiclesService,
    private router: Router
  ) {
    this.tryToReload();
  }

  ngOnInit() {
    localStorage.setItem('timesReloaded', '1');
    localStorage.setItem('timesReloadedDetail', '1');
    localStorage.setItem('timesReloadedServices', '1');
    localStorage.setItem('timesReloadedAbout', '1');
    this.getVehicles().then(() => {
      this.getPriceLimits();
      this.getMakers();
      this.getModels();
      this.getYears();
      this.getColors();
      this.getTransmissions();
      this.getTypes();
      this.line = document.getElementById('line-red');
      this.updateRangeData();

    }).then(() => {
      this.load = false;
    })
  }

  tryToReload() {
    if (localStorage.getItem('timesReloadedHome')) {
      if (localStorage.getItem('timesReloadedHome') === '1') {
        this.load = true;
        localStorage.setItem('timesReloadedHome', '2');
        location.reload();
      } else {
        this.load = true;
      }
    } else {
      localStorage.setItem('timesReloadedHome', '1');
    }
    
  }

  getPriceFormat(str: string | number): string {
    let price: string = str.toString();
    let price_array = price.split('');
    if (price_array.length > 6) {
      price_array.splice(price_array.length - 6, 0, ',');
    }
    price_array.splice(price_array.length - 3, 0, ',');
    price = price_array.join('');
    return price
  }

  getFilterOf(topic: string): Filter[] {
    var _filter: Filter[] = []
    this.filters.forEach(filter => {
      if (filter.topic.toLowerCase().indexOf(topic.toLowerCase()) > -1) {
        _filter.push(filter);
      }
    });
    return _filter.slice();
  }

  toogleFilter(topic: string, type: any) {
    const _newFilter: Filter = { topic: topic, type: type };
    switch (topic) {
      case 'year':
        this.currentYear = type;
        break;
      case 'maker':
        this.currentMaker = type;
        this.getModels(type);
        this.getYears();
        break;
      case 'model':
        this.currentModel = type;
        break;
      case 'color':
        this.currentColor = type;
        break;
      case 'transmision':
        this.currentTransmission = type;
        break;
      case 'type':
        this.currentTypes = type;
        break;
      default:
        break;
    }
  }

  

  goToSearch() {
    this.filters = [];
    if ((this.currentMaker.toLowerCase() != 'todos') && (this.currentMaker.toLowerCase() != 'marca')) {
      
      this.filters.push({ topic: 'make', type: this.currentMaker });
    }
    if ((this.currentModel.toLowerCase() != 'todos') && (this.currentModel.toLowerCase() != 'modelo')) {
      
      this.filters.push({ topic: 'model', type: this.currentModel });
    }
    if ((this.currentYear.toLowerCase() != 'todos') && (this.currentYear.toLowerCase() != 'año')) {
      
      this.filters.push({ topic: 'year', type: this.currentYear });
    }
    if ((this.currentColor.toLowerCase() != 'todos') && (this.currentColor.toLowerCase() != 'color')) {
      
      this.filters.push({ topic: 'color', type: this.currentColor });
    }
    if ((this.currentTransmission.toLowerCase() != 'todos') && (this.currentTransmission.toLowerCase() != 'transmisión')) {
      
      this.filters.push({ topic: 'transmission', type: this.currentTransmission });
    }
    if ((this.currentTypes.toLowerCase() != 'todos') && (this.currentTypes.toLowerCase() != 'tipo')) {
      
      this.filters.push({ topic: 'type', type: this.currentTypes });
    }

    

    const range = {
      min: this.currentMinVal,
      max: this.currentMaxVal
    }

    localStorage.setItem('filters_json', JSON.stringify(this.filters));
    localStorage.setItem('priceRanges_json', JSON.stringify(range));

    this.router.navigate(['autos-disponibles']);

  }

  getTextTruncated(text: string): string {
    let textTruncated: string;
    // 
    if (text.length > 29) {
      textTruncated = text.slice(0, 26);
      textTruncated += '...';
    } else {
      textTruncated = text;
    }
    return textTruncated;
  }

  removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  getVehicles = () => {
    return new Promise((resolve) => {
      this.service.getVehicles().subscribe(res => {
        this.vehicles = res;
        this.vehiclesOriginal = res;
        // console.log(this.vehicles);
        // console.log(this.vehicles.splice(115, 1)[0].minimum_price);        
        // console.log(this.vehicles.splice(123, 1)[0].minimum_price);        
        // console.log(this.vehicles.splice(95, 1)[0].minimum_price);
        // console.log(this.vehicles.splice(95, 1)[0].minimum_price);
        // console.log(this.vehicles.splice(107, 1)[0].minimum_price);
        resolve();
      });
    })
  }

  getPriceLimits() {
    this.vehicles.forEach(v => {
      if (this.minPrice === undefined) this.minPrice = (Number(v.minimum_price));
      if (this.maxPrice === undefined) this.maxPrice = (Number(v.minimum_price));
      if (Number(v.minimum_price) > this.maxPrice) this.maxPrice = (Number(v.minimum_price));
      if (Number(v.minimum_price) < this.minPrice) this.minPrice = (Number(v.minimum_price));
      if (Number(v.minimum_price) >= 9999990) console.log('Mas caro => ', v.model);
    });
    // this.minPrice = 50000;
    // this.maxPrice = 800000;
    this.realRange = this.maxPrice - this.minPrice;
    this.convertIndex = this.realRange / 200;
    // console.log('MinPrice:\t', this.minPrice, '\nMaxPrice\t', this.maxPrice, '\nDiference\t', this.realRange, '\nConvertIndex\t', this.convertIndex);
    
  }

  updateRangeData() {
     
    const min = this.val1 < this.val2 ? this.val1 : this.val2;
    const max = this.val1 > this.val2 ? this.val1 : this.val2;
    this.val1 = min;
    this.val2 = max;
    this.currentMinVal = (this.minPrice + (this.val1 * this.convertIndex));
    this.currentMaxVal = (this.minPrice + (this.val2 * this.convertIndex));
     
    this.line.style.marginLeft = `${(this.val1 + 6)}px`;
    this.line.style.width = `${(this.val2 - this.val1)}px`;
     
    // this.vehicles = this.getMatchOfVehiclesInRange(this.vehicles);
  }

  changeInput1(value) {
    this.val1 = value;
    this.updateRangeData();
  }

  changeInput2(value) {
    this.val2 = value;
    this.updateRangeData();
  }

  getMakers() {
    this.vehicles.forEach((vehicle) => {
      let newMaker = undefined;
      let existNow = undefined;
      if (this.makers.length > 0) {
        this.makers.forEach(maker => {
          if (maker.toUpperCase().indexOf(vehicle.make.toUpperCase()) === -1) {
            newMaker = vehicle.make.toUpperCase();
          } else {
            existNow = vehicle.make.toUpperCase();
            newMaker = undefined
          }
        });
      } else {
        this.makers.push(vehicle.make.toUpperCase());
      }
      if (existNow === undefined) {
        if (newMaker) {
          this.makers.push(newMaker);
        }
      }
    })
    this.makers.push('Todos');
    
  }
  getModels(maker ? : string) {
    if(maker){
      if(maker === 'Todos'){
        this.vehicles = this.vehiclesOriginal.slice();
      }else{
        this.currentModel = 'Modelo';
        this.vehicles = [];
        var _vehicles = this.vehiclesOriginal.slice();
        _vehicles.forEach(v=>{
          if(v.make.toLowerCase() === maker.toLowerCase()){
            this.vehicles.push(v);
          }
        });        
      }
      
    }
    this.models = [];
    this.vehicles.forEach((vehicle) => {
      let newModel = undefined;
      let existNow = undefined;
      if (this.models.length > 0) {
        this.models.forEach(model => {
          if (model.toUpperCase().indexOf(vehicle.model.toUpperCase()) === -1) {
            newModel = vehicle.model.toUpperCase();
          } else {
            existNow = vehicle.model.toUpperCase();
            newModel = undefined
          }
        });
      } else {
        this.models.push(vehicle.model.toUpperCase());
      }
      if (existNow === undefined) {
        if (newModel) {
          this.models.push(newModel);
        }
      }
    })
    
  }

  getYears() {
    this.years = [];
    this.vehicles.forEach((vehicle) => {
      let newyear = undefined;
      let existNow = undefined;
      if (this.years.length > 0) {
        this.years.forEach(year => {
          if (year.indexOf(vehicle.year) === -1) {
            newyear = vehicle.year;
          } else {
            existNow = vehicle.year;
            newyear = undefined
          }
        });
      } else {
        this.years.push(vehicle.year);
      }
      if (existNow === undefined) {
        if (newyear) {
          this.years.push(newyear);
        }
      }
    })
    this.orderYears();
      
  }

  getColors() {
    this.vehicles.forEach((vehicle) => {
      let newColor = undefined;
      let existNow = undefined;
      if (this.colors.length > 0) {
        this.colors.forEach(color => {
          if (color.indexOf(vehicle.color.toUpperCase()) === -1) {
            newColor = vehicle.color.toUpperCase();
          } else {
            existNow = vehicle.color.toUpperCase();
            newColor = undefined
          }
        });
      } else {
        this.colors.push(vehicle.color.toUpperCase());
      }
      if (existNow === undefined) {
        if (newColor) {
          this.colors.push(newColor);
        }
      }
    })
    this.colors.push('Todos');
      
  }

  getTransmissions() {
    this.transmissions = ["AUTOMÁTICO","MANUAL","MANUAL-AUTOMÁTICO"]
    // // console.log(this.transmissions);
  }

  getTypes() {
    this.vehicles.forEach((vehicle) => {
      let newType = undefined;
      let existNow = undefined;
      if (this.types.length > 0) {
        this.types.forEach(type => {
          if (type.indexOf(vehicle.type) === -1) {
            newType = vehicle.type;
          } else {
            existNow = vehicle.type;
            newType = undefined
          }
        });
      } else {
        this.types.push(vehicle.type);
      }
      if (existNow === undefined) {
        // console.log(newType);
        if (newType) {
          this.types.push(newType);
        }
      }
    })

    if (this.types.indexOf('NOTCHBACK') >= 0) {
      const i = this.types.indexOf('NOTCHBACK');
      this.types.splice(i, 1);
    }
    this.types.push('Todos');
      
  }

  orderYears() {
    var n, i, k, aux;
    n = this.years.length;
    for (k = 1; k < n; k++) {
      for (i = 0; i < (n - k); i++) {
        if (this.years[i] > this.years[i + 1]) {
          aux = this.years[i];
          this.years[i] = this.years[i + 1];
          this.years[i + 1] = aux;
        }
      }
    }
    this.years.push('Todos');
  }


}
