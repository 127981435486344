import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehiclesService } from '../common/services/vehicles.service';
import { Vehicle } from '../common/models/vehicle.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailService } from '../common/services/email.service';
import { resolve } from 'url';
import { GoogleAnalyticsService } from 'src/app/common/services/google-analytics.service';


@Component({
  selector: 'app-detail-vehicle',
  templateUrl: './detail-vehicle.component.html',
  styleUrls: ['./detail-vehicle.component.scss']
})
export class DetailVehicleComponent implements OnInit {

  //#region 
  idVehicle: string;
  currentVehicle: Vehicle = new Vehicle();
  vehicles: Vehicle[] = [];
  photos: string[] = [];
  currentIndexImage: number = 0;
  load = true;
  sendEmail = false;
  formFriend: FormGroup;
  formTestDrive: FormGroup;
  formMoreInfo: FormGroup;
  showModalImage:boolean = false;
  //#endregion

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private vehicleService: VehiclesService,
    private emailService: EmailService,
    private formBuilder: FormBuilder,
    private googleService: GoogleAnalyticsService
  ) {
    this.idVehicle = route.snapshot.params.id;
    this.tryToReload();
  }

  ngOnInit() {
    localStorage.setItem('timesReloaded', '1');
    localStorage.setItem('timesReloadedHome', '1');
    localStorage.setItem('timesReloadedServices', '1');
    localStorage.setItem('timesReloadedAbout', '1');

    this.getVehicleData(this.idVehicle);
    this.buildForms();

  }
  buildForms() {
    this.formFriend = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]
      )],
      phone: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
      friend_email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]
      )],
      type: ['friend', Validators.required],
      vehicle_id: [this.idVehicle, Validators.required]
    });

    this.formMoreInfo = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]
      )],
      phone: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
      message: ['', Validators.required],
      type: ['more_info', Validators.required],
      contact: ['email', Validators.required],
      vehicle_id: [this.idVehicle, Validators.required]
    });

    this.formTestDrive = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]
      )],
      phone: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
      day: ['', Validators.required],
      hour: ['', Validators.required],
      type: ['test_drive', Validators.required],
      contact: ['email', Validators.required],
      vehicle_id: [this.idVehicle, Validators.required]
    })


  }

  plusSlides(n: number) {
    this.showSlides(this.currentIndexImage += n);
  }

  togleContact1(e) {
    this.formMoreInfo.controls['contact'].setValue(e.target.value);
  }
  togleContact2(e) {
    this.formTestDrive.controls['contact'].setValue(e.target.value);
  }

  currentSlide(n: number) {
    this.showSlides(this.currentIndexImage = n);
  }

  doSentEmailMoreInfo() {

    this.emailService.sentEmail(this.formMoreInfo.value).subscribe(res => {

      // window.alert(res['message']);
      this.sendEmail = true;
      setTimeout(
        () => {
          this.sendEmail = false;
        }, 2000
      );
    }, err => console.error(err))
  }
  doSentTestDrive() {

    this.emailService.sentEmail(this.formTestDrive.value).subscribe(res => {

      // window.alert(res['message']);
      this.sendEmail = true;
      setTimeout(
        () => {
          this.sendEmail = false;
        }, 2000
      );
    }, err => console.error(err))

  }
  doSentToFriend() {

    this.emailService.sentEmail(this.formFriend.value).subscribe(res => {

      // window.alert(res['message']);
      this.sendEmail = true;
      setTimeout(
        () => {
          this.sendEmail = false;
        }, 2000
      );
    }, err => console.error(err))
  }



  showSlides(n: number) {
    this.currentIndexImage = n;

    if (n >= this.photos.length) {
      this.currentIndexImage = 0

    }
    if (n < 0) { this.currentIndexImage = this.photos.length - 1 }


  }



  getPriceFormat(str: string): string {
    let price: string = str;
    let price_array = price.split('');
    if (price_array.length > 6) {
      price_array.splice(price_array.length - 6, 0, ',');
    }
    price_array.splice(price_array.length - 3, 0, ',');
    price = price_array.join('');
    return price
  }
  tryToReload() {
    if (localStorage.getItem('timesReloadedDetail')) {
      if (localStorage.getItem('timesReloadedDetail') === '1') {
        this.load = true;
        localStorage.setItem('timesReloadedDetail', '2');
        location.reload();
      } else {
        this.load = true;
      }
    } else {
      localStorage.setItem('timesReloadedDetail', '1');
    }
    
  }

  getKilometers(str: string): string {
    // console.log('Kilometraje: ', str);
    let newString = str;
    newString = newString.replace(/[^\d]/g, '');
    // console.log('newString: ', newString);


    const mileage_array = newString.split('');
    if (mileage_array.length <= 3) {
      return newString;
    }
    if (mileage_array.length > 6) {
      mileage_array.splice(mileage_array.length - 6, 0, ',');
    }
    mileage_array.splice(mileage_array.length - 3, 0, ',');
    newString = mileage_array.join('');
    return newString;

    /*var parts: string[] = newString.split(',');
    var mileage: number = 0;
    if (parts.length === 1) {
      mileage = Number(str);
    } else {
      mileage = Number(parts[0] + parts[1]);
    }
    mileage = mileage / 1000;
    return mileage.toFixed(1)*/
  }

  getVehicleData(_id) {
    this.idVehicle = _id;
    return new Promise<void>((resolve, rejected) => {
      this.vehicleService.getVehicle(_id).subscribe(res => {

        this.currentVehicle = res[0];
        this.photos = this.getImages(this.currentVehicle.photos, this.currentVehicle.addPhotos);

        this.load = false;
        this.showSlides(this.currentIndexImage);
        this.getVehicles();
        this.analyticEvent('MARCA' + this.currentVehicle.make + ' - SERIE: ' + this.currentVehicle.serial_number, 'Vehiculo', 'Detalle del vehiculo');
        resolve();
      }, err => rejected());
    })
  }
  getVehicles() {
    this.vehicleService.getVehicles().subscribe(res => {
      const allVhicles: Vehicle[] = res;
      this.vehicles = allVhicles.reverse().splice(0, 4).slice();

    })
  }

  getImages(_images: string, _imagesadd: string): string[] {
    const images: string[] = JSON.parse(_images);
    const images2: string[] = [];
    images.forEach(element => {
      images2.push('https://avaluos.itelisoft.click/resource/images/' + element);
    });

    if (_imagesadd !== null) {
      const imagesadd: string[] = JSON.parse(_imagesadd);
      imagesadd.forEach(element => {
        images2.push('https://avaluos.itelisoft.click/resource/images/' + element);
      });
      images2.pop();
    }

    console.log('images2: ', images2);
    return images2;
  }

  toogleFullImage(){
    this.showModalImage = !this.showModalImage;
    console.log(this.showModalImage);
  }

  getTheFirstImage(_images: string): string {
    const images: string[] = JSON.parse(_images);
    const img: string = 'https://avaluos.itelisoft.click/resource/images/' + images[0];
    return img;
  }

  stylingText(str: string): string {
    let _str = str.toLowerCase();
    return _str;
  }

  getTextTruncated(text: string): string {
    let textTruncated: string;
    if (text.length > 13) {
      textTruncated = text.slice(0, 10);
      textTruncated += '...';
    } else {
      textTruncated = text;
    }
    return textTruncated;
  }

  goToDetailsOf(id: string) {
    this.getVehicleData(id).then(() => {
      this.scrollToTop();
    }).catch(() => {
      window.alert('Algo salio mal');
      this.router.navigate(['']);
    });
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 641) {
        window.scrollTo(0, pos - 20); 
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }

  analyticEvent(name, category, action) {
    this.googleService.eventEmitter(name, category, action);
  }

}