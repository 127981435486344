import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { VehiclesService } from '../common/services/vehicles.service';
import { Vehicle } from '../common/models/vehicle.model';
import { Event, Router } from '@angular/router';
import { EventListener } from '@angular/core/src/debug/debug_node';

interface Filter {
  topic: string,
  type: string
}

@Component({
  selector: 'app-list-vehicles',
  templateUrl: './list-vehicles.component.html',
  styleUrls: ['./list-vehicles.component.scss']
})
export class ListVehiclesComponent implements OnInit {

  //#region 
  public scrollValue: number = 0;
  public screenWidth: number = 0;

  @HostListener("window:scroll", ['$event'])
  doSomethingOnWindowsScroll($event: any) {
    this.scrollValue = $event.srcElement.children[0].scrollTop;
    // console.log(this.scrollValue);
    this.screenWidth = window.innerWidth
  }
  //#endregion

  //#region 
  page = 1;
  pageSize = 20;
  vehicles: Array<Vehicle> = [];
  vehiclesOriginal: Vehicle[] = [];
  updateModels:boolean;
  updateMakrs:boolean;
  //#endregion

  //------RANGE-STUFF----------
  //#region
  minPrice: number = 9999999;
  maxPrice: number = 99999;
  // minPrice: number = 50000;
  // maxPrice: number =800000;
  realRange: number;
  convertIndex: number;
  val1: number = 0;
  val2: number = 200;
  currentMinVal: number = 100000;
  currentMaxVal: number = 100000;
  line;
  load = true;
  //#endregion

  //-------TOPICS-------
  //#region 
  makers: string[] = [];
  models: string[] = [];
  years: string[] = [];
  colors: string[] = [];
  transmissions: string[] = [];
  types: string[] = [];
  //#endregion


  //-------FILTERS-----
  filters: Filter[] = [];
  checksActivated: any[] = [];

  constructor(
    private service: VehiclesService,
    private router: Router
  ) {
    this.tryToReload()
  }

  ngOnInit() {
    localStorage.setItem('timesReloadedHome', '1');
    localStorage.setItem('timesReloadedDetail', '1');
    localStorage.setItem('timesReloadedServices', '1');
    localStorage.setItem('timesReloadedAbout', '1');
    this.getVehicles().then(() => {
      this.vehicles.forEach(v => {
        if (Number(v.minimum_price) > 800000) {
          // console.log(v.model, v.minimum_price);
        }
      })
      this.getPriceLimits();
      this.getMakers();
      this.getModels();
      this.getYears();
      this.getColors();
      this.getTransmissions();
      this.getTypes();
      this.load = false;
      this.line = document.getElementById('line-red');
      this.updateRangeData();
      this.checkFiltersExist();
      // this.pruebaExReg();
    })
  }


  quitarFilters() {
    this.filters = [];
    this.filterVehicles();
    this.checksActivated.forEach(check => {
      check.checked = 0;
    });
    this.checksActivated = [];

    this.val1 = 0;
    this.val2 = 200;
    this.updateRangeData();
    this.getYears();
  }

  getPriceLimits() {
    this.vehicles.forEach(v => {
      if (this.minPrice === undefined) this.minPrice = (Number(v.minimum_price));
      if (this.maxPrice === undefined) this.maxPrice = (Number(v.minimum_price));
      if (Number(v.minimum_price) > this.maxPrice) this.maxPrice = (Number(v.minimum_price));
      if (Number(v.minimum_price) < this.minPrice) this.minPrice = (Number(v.minimum_price));
    });
    // this.minPrice = 50000;
    // this.maxPrice = 800000;
    this.realRange = this.maxPrice - this.minPrice;
    this.convertIndex = this.realRange / 200;
    //console.log('MinPrice:\t', this.minPrice, '\nMaxPrice\t', this.maxPrice, '\nDiference\t', this.realRange, '\nConvertIndex\t', this.convertIndex  );
  }

  updatePage(e) {
    console.log(e);
    this.page = e;
    window.scrollTo(0, 446);
  }
  checkFiltersExist() {
    setTimeout(
      () => {
        if (localStorage.getItem('filters_json')) {
          const _filters: Filter[] = JSON.parse(localStorage.getItem('filters_json'));
          const _range = JSON.parse(localStorage.getItem('priceRanges_json'));
          localStorage.removeItem('filters_json');
          localStorage.removeItem('priceRanges_json');
          this.filters = _filters.slice();
          this.currentMinVal = _range.min;
          this.currentMaxVal = _range.max;
          // console.log(_filters);
          this.filterVehicles();
          this.getModels();
          this.getYears();
        }
      }, 800
    );
  }
  tryToReload() {
    if (localStorage.getItem('timesReloaded')) {
      if (localStorage.getItem('timesReloaded') === '1') {
        this.load = true;
        localStorage.setItem('timesReloaded', '2');
        location.reload();
      } else {
        this.load = true;
      }
    } else {
      localStorage.setItem('timesReloaded', '1');
    }
    
  }

  updateRangeData() {
    const min = this.val1 < this.val2 ? this.val1 : this.val2;
    const max = this.val1 > this.val2 ? this.val1 : this.val2;
    this.val1 = min;
    this.val2 = max;
    this.currentMinVal = (this.minPrice + (this.val1 * this.convertIndex));
    this.currentMaxVal = (this.minPrice + (this.val2 * this.convertIndex));
    this.line.style.marginLeft = `${(this.val1 + 6)}px`;
    this.line.style.width = `${this.val2 - this.val1}px`;
    this.filterVehicles();

  }

  changeInput1(value) {


    this.val1 = value;
    this.updateRangeData();

  }

  changeInput2(value) {
    this.val2 = value;
    this.updateRangeData();

  }

  stylingText(str: string): string {
    let _str = str.toLowerCase();
    return _str;
  }

  goToDetail(id) {
    this.router.navigate([`detalle/${id}`]);
  }
  getVehicles = () => {
    return new Promise<void>((resolve) => {
      this.service.getVehicles().subscribe(res => {
        this.vehicles = res;
        this.vehiclesOriginal = res;
        resolve();
      });
    })
  }

  removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  getTheFirstImage(_images: string): string {
    const images: string[] = JSON.parse(_images);
    const img: string = 'https://avaluos.itelisoft.click/resource/images/' + images[0];
    // console.log(img);
    return img;
  }

  getNumber(i) {
    // console.log(i);
    return i;
  }


  toogleFilter(e, topic: string, type: any) {
    const elementId = e.srcElement.id;
    const currentCheckStatus: Boolean = e.currentTarget.checked;
    const _newFilter: Filter = { topic: topic, type: type };
    if (currentCheckStatus) {
      this.filters.push(_newFilter);    
      this.tryToUpdateModelList(_newFilter);  
      this.addCheck(elementId);
    } else {
      this.filters.forEach((filter, index) => {
        if (filter.type.toLowerCase().indexOf(_newFilter.type.toLowerCase()) > -1) {
          this.removeCheck(elementId);
          this.filters.splice(index, 1);
        }
      });
      this.tryToUpdateModelList(_newFilter);  
    }
    

    this.filterVehicles();
  }

  tryToUpdateModelList(_currentFilter : Filter):void{
    var indexToDelete : number[] = [];
    var indexToDeleteOrdered : number[] = [];
    console.log(this.filters, _currentFilter);
    if (_currentFilter.topic === "make"){
      console.log('\t ->make')
      this.filters.forEach((f,i)=>{
        if(f.topic != "make"){
          console.log('\t\t->'+f.topic+'-'+f.type);
          indexToDelete.push(i)
        }        
      });
      indexToDeleteOrdered = indexToDelete.sort().reverse().slice();
      indexToDeleteOrdered.forEach(i=>{
        this.filters.splice(i,1);
        this.checksActivated[i].checked = 0;
        this.checksActivated.splice(i,1);        
      });
      this.updateModels = true;     
    }
    if (_currentFilter.topic === "model" || _currentFilter.topic === "make") {
      this.updateMakrs = true;
    }
  }

  addCheck(str: string) {
    this.checksActivated.push(document.getElementById(str));
    
    // console.log(this.checksActivated,str);
  }
  removeCheck(str: string) {
    var index = 0;
    this.checksActivated.forEach((element, i) => {
      const id: string = element['id'];
      if (id.indexOf(str) > -1) {
        index = i;
      }
    });
    this.checksActivated.splice(index, 1);
    // console.log(this.checksActivated);
  }


  filterVehicles() {
    // // console.log('\n\t------------<Filter start');
    this.page = 1;
    if (this.filters.length > 0) {
      var _vehiclesFiltered: Vehicle[] = this.vehiclesOriginal.slice();
      var filter1: Filter[] = this.getFilterOf('make');
      var filter2: Filter[] = this.getFilterOf('year');
      var filter3: Filter[] = this.getFilterOf('model');
      var filter4: Filter[] = this.getFilterOf('color');
      var filter5: Filter[] = this.getFilterOf('transmission');
      var filter6: Filter[] = this.getFilterOf('type');

      if (filter1.length > 0) {
        _vehiclesFiltered = this.getMatchOfVehiclesInFilter(filter1, _vehiclesFiltered);
      }//Filtro año
      if (filter2.length > 0) {
        _vehiclesFiltered = this.getMatchOfVehiclesInFilter(filter2, _vehiclesFiltered);
      }//Filtro fabricante
      if (filter3.length > 0) {
        _vehiclesFiltered = this.getMatchOfVehiclesInFilter(filter3, _vehiclesFiltered);
      }//Filtro Modelo
      if (filter4.length > 0) {
        _vehiclesFiltered = this.getMatchOfVehiclesInFilter(filter4, _vehiclesFiltered);
      }//Filtro color
      if (filter5.length > 0) {
        _vehiclesFiltered = this.getMatchOfVehiclesTransmissions(filter5, _vehiclesFiltered);
      }//Filtro transmision
      if (filter6.length > 0) {
        _vehiclesFiltered = this.getMachOfVehiclesTypes(filter6, _vehiclesFiltered);
      }//Filtro estilo      


      //Filtro por rangos actuales = _vehiclesFiltered.slice();
      this.vehicles = this.getMatchOfVehiclesInRange(_vehiclesFiltered);
      if(this.updateModels){
        this.getModels();        
        // this.getYears();
        this.updateModels = false;
      }

    } else {
      this.vehicles = this.getMatchOfVehiclesInRange(this.vehiclesOriginal);
      this.getModels();
      // this.getYears();
      this.updateModels = false;
    }

    if (this.updateMakrs) {
      this.getYears();
      this.updateMakrs = false;
    }
    // console``.log('\t------------<Filter end');
  }


  getFilterOf(topic: string): Filter[] {
    var _filter: Filter[] = []
    this.filters.forEach(filter => {
      if (filter.topic.toLowerCase().indexOf(topic.toLowerCase()) > -1) {
        _filter.push(filter);
      }
    });
    return _filter.slice();
  }

  getMatchOfVehiclesInFilter(topicFilters: Filter[], _vehicles: Vehicle[]): Vehicle[] {
    var vehiclesMatch: Vehicle[] = [];
    topicFilters.forEach(filter => {
      _vehicles.forEach(vehicle => {
        const processedVehicle = this.removeAccents(vehicle[filter.topic].toLowerCase());
        const processedFilter = this.removeAccents(filter.type.toLowerCase());
        if (processedVehicle.indexOf(processedFilter) > -1) {
          vehiclesMatch.push(vehicle);
        }
      });
    });
    return vehiclesMatch.slice();
  }

  getMatchOfVehiclesTransmissions(topicFilters: Filter[], _vehicles: Vehicle[]): Vehicle[] {
    var vehiclesMatch: Vehicle[] = [];
    var pattern1 = /aut/i;
    var pattern2 = /man/i;
    topicFilters.forEach(filter => {
      _vehicles.forEach(vehicle => {
        if (filter.type === 'AUTOMÁTICO') {
          var _tra: string = this.removeAccents(vehicle.transmission.toLowerCase());
          if (_tra.match(pattern1)) {
            vehiclesMatch.push(vehicle);
          }
        }
        if (filter.type === 'MANUAL') {
          var _tra: string = this.removeAccents(vehicle.transmission.toLowerCase());
          if (_tra.match(pattern2)) {
            vehiclesMatch.push(vehicle);
          }
        }
        if (filter.type === 'MANUAL-AUTOMÁTICO') {
          var _tra: string = this.removeAccents(vehicle.transmission.toLowerCase());
          if (_tra.match(pattern1) && _tra.match(pattern2)) {
            vehiclesMatch.push(vehicle);
          }
        }
      });
    });
    return vehiclesMatch.slice();
  }

  getMachOfVehiclesTypes(topicFilters: Filter[], _vehicles: Vehicle[]): Vehicle[] {
    var vehiclesMatch: Vehicle[] = [];
    var pattern1 = /sedan/i;
    var pattern2 = /nothback/i;
    topicFilters.forEach(filter => {
      _vehicles.forEach(vehicle => {
        const processedVehicle = this.removeAccents(vehicle[filter.topic].toLowerCase());
        const processedFilter = this.removeAccents(filter.type.toLowerCase());
        if (filter.type === 'SEDAN') {          
          if ((processedVehicle.indexOf(processedFilter) > -1) || (processedVehicle.indexOf('notchback') > -1) ) {
            vehiclesMatch.push(vehicle);
          }
        } else {
          if (processedVehicle.indexOf(processedFilter) > -1) {
            vehiclesMatch.push(vehicle);
          }
        }
      })
    });



    return vehiclesMatch.slice();
  }


  getMatchOfVehiclesInRange(_vehicles: Vehicle[]): Vehicle[] {
    var vehiclesMatch: Vehicle[] = [];
    _vehicles.forEach(vehicle => {
      let price: number = Number(vehicle.minimum_price);
      if ((this.currentMinVal <= price) && (price <= this.currentMaxVal)) vehiclesMatch.push(vehicle);
    })
    return vehiclesMatch.slice();
  }


  getMakers() {
    this.vehicles.forEach((vehicle) => {
      let newMaker = undefined;
      let existNow = undefined;
      if (this.makers.length > 0) {
        this.makers.forEach(maker => {
          if (maker.toUpperCase().indexOf(vehicle.make.toUpperCase()) === -1) {
            newMaker = vehicle.make.toUpperCase();
          } else {
            existNow = vehicle.make.toUpperCase();
            newMaker = undefined
          }
        });
      } else {
        this.makers.push(vehicle.make.toUpperCase());
      }
      if (existNow === undefined) {
        if (newMaker) {
          this.makers.push(newMaker);
        }
      }
    })
    // console.log(this.vehicles);
  }
  getModels() {
    this.models = [],
    this.vehicles.forEach((vehicle) => {
      let newModel = undefined;
      let existNow = undefined;
      if (this.models.length > 0) {
        this.models.forEach(model => {
          if (model.toUpperCase().indexOf(vehicle.model.toUpperCase()) === -1) {
            newModel = vehicle.model.toUpperCase();
          } else {
            existNow = vehicle.model.toUpperCase();
            newModel = undefined
          }
        });
      } else {
        this.models.push(vehicle.model.toUpperCase());
      }
      if (existNow === undefined) {
        if (newModel) {
          this.models.push(newModel);
        }
      }
    });
  }

  getYears() {
    this.years = [];
    this.vehicles.forEach((vehicle) => {
      let newyear = undefined;
      let existNow = undefined;
      if (this.years.length > 0) {
        this.years.forEach(year => {
          if (year.indexOf(vehicle.year) === -1) {
            newyear = vehicle.year;
          } else {
            existNow = vehicle.year;
            newyear = undefined
          }
        });
      } else {
        this.years.push(vehicle.year);
      }
      if (existNow === undefined) {
        if (newyear) {
          this.years.push(newyear);
        }
      }
    });    
    this.orderYears();
    // // console.log(this.years);
  }

  getColors() {
    this.vehicles.forEach((vehicle) => {
      let newColor = undefined;
      let existNow = undefined;
      if (this.colors.length > 0) {
        this.colors.forEach(color => {
          if (color.indexOf(vehicle.color.toUpperCase()) === -1) {
            newColor = vehicle.color.toUpperCase();
          } else {
            existNow = vehicle.color.toUpperCase();
            newColor = undefined
          }
        });
      } else {
        this.colors.push(vehicle.color.toUpperCase());
      }
      if (existNow === undefined) {
        if (newColor) {
          this.colors.push(newColor);
        }
      }
    })
    // // console.log(this.colors);
  }

  getTransmissions() {
    this.transmissions = ["AUTOMÁTICO", "MANUAL", "MANUAL-AUTOMÁTICO"]
    // // console.log(this.transmissions);
  }

  getTypes() {
    this.vehicles.forEach((vehicle) => {
      let newType = undefined;
      let existNow = undefined;
      if (this.types.length > 0) {
        this.types.forEach(type => {
          if (type.indexOf(vehicle.type) === -1) {
            newType = vehicle.type;
          } else {
            existNow = vehicle.type;
            newType = undefined
          }
        });
      } else {
        this.types.push(vehicle.type);
      }
      if (existNow === undefined) {
        if (newType) {
          this.types.push(newType);
        }
      }
    })
    if (this.types.indexOf('NOTCHBACK') >= 0) {
      const i = this.types.indexOf('NOTCHBACK');
      this.types.splice(i, 1);
    }
    // console.log(this.types);
  }

  orderYears() {
    var n, i, k, aux;
    n = this.years.length;
    for (k = 1; k < n; k++) {
      for (i = 0; i < (n - k); i++) {
        if (this.years[i] > this.years[i + 1]) {
          aux = this.years[i];
          this.years[i] = this.years[i + 1];
          this.years[i + 1] = aux;
        }
      }
    }
  }

  getPriceFormat(str: string | number): string {
    let price: string = str.toString();
    let price_array = price.split('');
    if (price_array.length > 6) {
      price_array.splice(price_array.length - 6, 0, ',');
    }
    price_array.splice(price_array.length - 3, 0, ',');
    price = price_array.join('');
    return price
  }

  getTextTruncated(text: string): string {
    let textTruncated: string;
    // console.log();
    if (text.length > 13) {
      textTruncated = text.slice(0, 10);
      textTruncated += '...';
    } else {
      textTruncated = text;
    }
    return textTruncated;
  }



  goToDetailsOf(id: string) {
    this.router.navigate([`detalle/${id}`]);
  }
}
