import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ListVehiclesComponent } from './list-vehicles/list-vehicles.component';
import { DetailVehicleComponent } from './detail-vehicle/detail-vehicle.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { MoreServicesComponent } from './more-services/more-services.component';
import { OfertaMesComponent } from './oferta-mes/oferta-mes.component';
import { AboutComponent } from './about/about.component';

export const routes: Routes = [
    { path: 'slideshow', component: SlideshowComponent },
    { path: 'servicios', component: MoreServicesComponent },
    { path: 'autos-disponibles', component: ListVehiclesComponent },
    { path: 'oferta-mes', component: OfertaMesComponent },
    { path: 'acerca-de', component: AboutComponent },
    { path: 'detalle/:id', component: DetailVehicleComponent },
    { path: 'inicio', component: HomeComponent },
    { path: '', redirectTo: '/inicio', pathMatch: 'full' },
    { path: '**', redirectTo: '/inicio', pathMatch: 'full' }
];
