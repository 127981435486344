import { Injectable } from '@angular/core';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter(eventName: string, eventCategory: string, eventAction: string) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: null,
      eventAction: eventAction,
      eventValue: null
    });
  }
}
