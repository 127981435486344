export interface Vehicle {
    id: number,
    type: string,
    color: string,
    make: string,
    model: string,
    version: string,
    year: string,
    bill: any,
    photos: any,
    addPhotos?: any,
    id_avaluos?: any
    minimum_price: string,
    maximum_price: string,
    minimum_year: string,
    maximum_year: string,
    status: string,
    branch_office: string,
    visible: string,
    serial_number: string,
    transmission: string,
    mileage: string,
    internal: string,
    notes: string,
    actual_state: string,
    id_user: number,
    id_client: number,
    remember_token: string,
    created_at: string,
    updated_at: string
}

export class Vehicle {
    id: number;
    type: string;
    color: string;
    make: string;
    model: string;
    version: string;
    year: string;
    bill: any;
    photos: any;
    minimum_price: string;
    maximum_price: string;
    minimum_year: string;
    maximum_year: string;
    status: string;
    branch_office: string;
    visible: string;
    serial_number: string;
    transmission: string;
    mileage: string;
    internal: string;
    notes: string;
    actual_state: string;
    id_user: number;
    id_client: number;
    remember_token: string;
    created_at: string;
    updated_at: string

    constructor(data?: Vehicle) {
        if (data) {
            this.id = data.id;
            this.type = data.type;
            this.color = data.color;
            this.make = data.make;
            this.model = data.model;
            this.version = data.version;
            this.year = data.year;
            this.bill = data.bill;
            this.photos = data.photos;
            this.minimum_price = data.minimum_price;
            this.maximum_price = data.maximum_price;
            this.minimum_year = data.minimum_year;
            this.maximum_year = data.maximum_year;
            this.status = data.status;
            this.branch_office = data.branch_office;
            this.visible = data.visible;
            this.serial_number = data.serial_number;
            this.transmission = data.transmission;
            this.mileage = data.mileage;
            this.internal = data.internal;
            this.notes = data.notes;
            this.actual_state = data.actual_state;
            this.id_user = data.id_user;
            this.id_client = data.id_client;
            this.remember_token = data.remember_token;
            this.created_at = data.created_at;
            this.updated_at = data.updated_at;
        }else{
            this.id = 0;
            this.type = '';
            this.color = '';
            this.make = '';
            this.model = '';
            this.version = '';
            this.year = '';
            this.bill = '';
            this.photos = '';
            this.minimum_price = '';
            this.maximum_price = '';
            this.minimum_year = '';
            this.maximum_year = '';
            this.status = '';
            this.branch_office = '';
            this.visible = '';
            this.serial_number = '';
            this.transmission = '';
            this.mileage = '';
            this.internal = '';
            this.notes = '';
            this.actual_state = '';
            this.id_user = 0;
            this.id_client = 0;
            this.remember_token = '';
            this.created_at = '';
            this.updated_at = '';
        }
    }
}

