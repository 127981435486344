import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/common/services/google-analytics.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  route: string;

  constructor(
    private router: Router,
    private googleService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    console.log('Reuta: ', this.router.url);
    this.route = this.router.url;
  }

  show(target:string){
    this.router.navigate([target]);
  }

  analyticEvent(name, category, action) {
    this.googleService.eventEmitter(name, category, action);
  }

}
