import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmailInfo, EmailTestDrive, EmailFriend } from '../models/email.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  
  URI:string = 'https://avaluos.itelisoft.click/api-autorama/public/api/send/email'
  constructor(private http:HttpClient) { }

  sentEmail(emailData: EmailInfo | EmailTestDrive | EmailFriend){
    return this.http.post( this.URI , emailData );
  }

}
