import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  load = true;

  constructor() {
    this.tryToReload();
   }

  ngOnInit() {
    localStorage.setItem('timesReloaded', '1');
    localStorage.setItem('timesReloadedHome', '1');
    localStorage.setItem('timesReloadedDetail', '1');
    localStorage.setItem('timesReloadedServices', '1');
  }

  tryToReload() {
    if (localStorage.getItem('timesReloadedAbout')) {
      if (localStorage.getItem('timesReloadedAbout') === '1') {
        this.load = true;
        localStorage.setItem('timesReloadedAbout', '2');
        location.reload();
      } else {
        this.load = true;
      }
    } else {
      localStorage.setItem('timesReloadedAbout', '1');
    }
    
  }

}
