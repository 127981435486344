import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { routes } from './app.routing';
import { ListVehiclesComponent } from './list-vehicles/list-vehicles.component';
import { DetailVehicleComponent } from './detail-vehicle/detail-vehicle.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import {OwlCarousel, OwlModule} from 'ngx-owl-carousel';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { MoreServicesComponent } from './more-services/more-services.component';
import { AboutComponent } from './about/about.component';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
import { OfertaMesComponent } from './oferta-mes/oferta-mes.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ListVehiclesComponent,
    DetailVehicleComponent,
    SlideshowComponent,
    MoreServicesComponent,
    AboutComponent,
    OfertaMesComponent
  ],
  imports: [  
    CommonModule,    
    NgxPaginationModule,  
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserModule, 
    OwlModule,   
    RouterModule.forRoot(
      routes,
      { useHash: true, enableTracing: false, scrollPositionRestoration: 'enabled' }
    ),
  ],
  providers: [GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
