import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-services',
  templateUrl: './more-services.component.html',
  styleUrls: ['./more-services.component.scss']
})
export class MoreServicesComponent implements OnInit {

  load = true;

  constructor() {
    this.tryToReload();
  }

  ngOnInit() {
    localStorage.setItem('timesReloaded', '1');
    localStorage.setItem('timesReloadedHome', '1');
    localStorage.setItem('timesReloadedDetail', '1');
    localStorage.setItem('timesReloadedAbout', '1');
  }

  tryToReload() {
    if (localStorage.getItem('timesReloadedServices')) {
      if (localStorage.getItem('timesReloadedServices') === '1') {
        this.load = true;
        localStorage.setItem('timesReloadedServices', '2');
        location.reload();
      } else {
        this.load = true;
      }
    } else {
      localStorage.setItem('timesReloadedServices', '1');
    }
    
  }

}
